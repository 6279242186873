import styled from "@emotion/styled"
import React from "react"
import { useSiteMetadata } from "../../hooks/use-site-metadata"
import { theme } from "../../theme"
import { SocialButton } from "./social-button"

const List = styled.ul`
  margin: 0.5rem 0;
  padding: 0;
  display: flex;
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }

  a {
    background: ${theme.colors.highlight};
  }
`

export const SocialLinks = ({ className, links }) => {
  const { author } = useSiteMetadata()
  const socialLinks = links ?? author.socialLinks

  return (
    <List className={className}>
      {socialLinks.map(link => (
        <li key={link}>
          <SocialButton link={link} />
        </li>
      ))}
    </List>
  )
}
