import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import { Bio } from "../components/bio"
import { LiftingGirl } from "../components/characters/lifting-girl"
import { Layout, Page, Row, Footer, Copyright } from "../components/layout"
import { SEO } from "../components/seo"
import { PAGE_TYPES } from "../components/seo/schemas"
import { SocialLinks } from "../components/social/social-links"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { theme } from "../theme"

const AboutPage = styled(Page)`
  background: linear-gradient(
    180deg,
    ${theme.colors.backgroundDark} 0%,
    ${theme.colors.backgroundLight} 100%
  );
  color: ${theme.colors.textLight};

  h2 {
    color: ${theme.colors.secondary};
  }
`

const AboutFooter = styled(Footer)`
  flex-direction: column;
  background: ${theme.colors.secondary};
  margin-top: 6rem;
  padding: 1rem;

  a {
    color: ${theme.colors.textLight};
    border-bottom-color: ${theme.colors.textLight};
  }

  dl {
    display: grid;
    margin-top: 0;
    font-size: ${theme.fontSizes[1]};
  }

  dt {
    grid-column: 1;
    text-align: right;
  }

  dd {
    grid-column: 2;
    margin-inline-start: 1.5rem;
  }
`

const Flex = styled.div`
  display: flex;
`

const StyledBio = styled(Bio)`
  flex-direction: row-reverse;

  h2 {
    margin: 0;
  }

  header + ul {
    color: ${theme.colors.primary};
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      line-height: 1.2;
    }
  }
`

const LiftingGirlImage = styled(LiftingGirl)`
  margin: 1.5rem auto;

  @media (min-width: 650px) {
    margin: 0 0 1.5rem 1.5rem;
    float: right;
    width: 450px;
  }
`

const BigSocialLinks = styled(SocialLinks)`
  a {
    width: 4rem;
    height: 4rem;
    font-size: ${theme.fontSizes[4]};
  }
`

const About = () => {
  const { socialLinks } = useSiteMetadata()

  return (
    <AboutPage>
      <Layout
        footer={() => (
          <AboutFooter>
            <dl>
              <dt>
                <a href="https://www.instagram.com/maestrodumii">
                  @maestrodumii
                </a>
              </dt>
              <dd>art and design</dd>
              <dt>
                <a href="https://twitter.com/ognus">@ognus</a>
              </dt>
              <dd>development</dd>
            </dl>
            <Copyright />
          </AboutFooter>
        )}
      >
        <SEO title="About" seoSchemaOrgType={PAGE_TYPES.ABOUT} />

        <article>
          <Row>
            <header>
              <h1>About</h1>
            </header>

            <LiftingGirlImage />

            <p>
              Girl Champion Project was born to share the stories of women who
              do strength sports.
            </p>
            <p>
              <strong>Because every girl is a champion!</strong>
            </p>

            <BigSocialLinks links={socialLinks} />

            <h2>The WHY</h2>
            <p>
              As I had my baby, I lost myself. I felt that my body does not
              belong to me anymore. In months (and years) to come, I struggled
              to rebuild myself as a person who used to be an athlete, a
              scholar, a friend. As I was already in my 30-ties I have decided
              that investing in some strength training could be good for my
              long-term health.
            </p>
            <p>
              I heard about CrossFit and thought that it would be worth trying
              as it has some strength related components and could start me with
              some exercises and guidance. Quickly, it became obvious that
              getting stronger is fun and allowed me to feel better in so many
              ways. I have also met some phenomenal athletes there; many of whom
              were women. The community of awesome females was similar to my
              primary school handball team except we had children now and were
              not attending the same school. This experience has changed my
              life.
            </p>
            <p>
              I have started strength sports for health, but the thing I truly
              appreciate is a community of awesome women I found. Their efforts
              in the gym and life stories inspire me every day, even beyond the
              training.
            </p>
            <p>
              This website was born to amplify the stories of girls of all ages
              on their journey to strength and so much more.
            </p>
            <p>I hope you can find your empowerment and motivation here too.</p>
            <p>
              If you are or know a woman who would like to share their story on
              the Girl Champion Project’s platform, please contact me through
              the <Link to="/contact">contact form</Link>.
            </p>
            <p>Hugs, Alex</p>

            <Flex>
              <StyledBio>
                <header>
                  <h2>Alex Macznik</h2>
                  PhD in Sports Physiotherapy
                </header>

                <ul>
                  <li>Researcher</li>
                  <li>Excercise enthusiast</li>
                </ul>

                <SocialLinks />
              </StyledBio>
            </Flex>
          </Row>
        </article>
      </Layout>
    </AboutPage>
  )
}

export default About
