import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import styled from "@emotion/styled"

const CharacterImage = ({ className }) => {
  const {
    file: { image },
  } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "girl-lifting.png" }) {
          image: childImageSharp {
            fluid(quality: 90, maxWidth: 450, srcSetBreakpoints: [350, 450]) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Image
      fluid={image.fluid}
      role="img"
      aria-label="Lifting Girl Character"
      className={className}
    />
  )
}

export const LiftingGirl = styled(CharacterImage)`
  max-width: 450px;
`
