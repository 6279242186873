import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import React from "react"
import { SocialLinks } from "./social/social-links"

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Avatar = styled(Image)`
  min-width: 70px;
  border-radius: 100%;
  margin: 0 1rem;
`

const DefaultContent = (
  <>
    <strong>Alex Macznik</strong>
    <div>PhD in Sports Physiotherapy</div>
    <SocialLinks />
  </>
)

export const Bio = ({ children = DefaultContent, className }) => {
  const { avatar } = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 70, height: 70) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Container className={className}>
      <Avatar
        fixed={avatar.childImageSharp.fixed}
        alt="Alex Mącznik"
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <div>{children}</div>
    </Container>
  )
}
