import styled from "@emotion/styled"
import React from "react"
import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa"
import { theme } from "../../theme"

const ICONS = {
  instagram: FaInstagram,
  facebook: FaFacebookF,
  twitter: FaTwitter,
  linkedin: FaLinkedinIn,
}

const COLORS = {
  instagram:
    "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
  facebook: "#4267B2",
  twitter: "#1DA1F2",
  linkedin: "#2867B2",
}

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  border: 0;
  color: ${theme.colors.textLight};
  background: ${({ color }) => color};

  &:hover {
    opacity: 0.5;
  }
`

function getSocialIcon(link) {
  const [, name] = link.match(/^https?:\/\/(.+?).com\/?.*$/)
  const Icon = ICONS[name]
  const color = COLORS[name]

  if (Icon && color) {
    return { color, Icon }
  }

  console.warn(`SocialButton: ${name} not supported`)
  return {}
}

export const SocialButton = ({ className, link }) => {
  const { color, Icon } = getSocialIcon(link)

  if (Icon) {
    return (
      <Button className={className} href={link} color={color}>
        <Icon />
      </Button>
    )
  }

  return null
}
